import { ArrowCircleRightSharp, ArrowCircleUp, CheckCircleOutline, ConstructionOutlined, RadioButtonUnchecked } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { CurrentPContext, MasterContext, OrderContext, SocketContext, UserContext } from "../../../ContextApi/context";
import { useHistory } from 'react-router';
import utilsUsers from "../../../_Utils/utilsUsers";
import InputSet from "../../../DTechComp/InputSet";
import utilsCodes from "../../../_Utils/utilsCodes";
import PopUpRegular from "../../../DTechComp/PopUpRegular";
import PopUpMsg from "../../../DTechComp/PopUpMsg";
import MyDateTime from "../../../DTechComp/MyDateTime";
import ShowMyOrder from "./showMyOrder";
import utilsRests from "../../../_Utils/utilsRests";
import ConnUrls from "../../../Connections/ConnectURL";
import BigPopUpMsg from "../../../DTechComp/BigPopUpMsg";
import utilsOrders from "../../../_Utils/utilsOrders";
import { io } from 'socket.io-client';
import Out_Of_Hours from "../../../DTechComp/Pop_Fixed/Out_Of_Hours";
import No_TakeAway from "../../../DTechComp/Pop_Fixed/No_TakeAway";
import No_Deliverys from "../../../DTechComp/Pop_Fixed/No_Deliverys";
import myFunctions from "../../../myFunctions";

const mySocket = io.connect(ConnUrls.socketClient, {
    auth: {
        token: localStorage.getItem("lolusrtoken")
    },
    query: {
        userType: "client"
    }
}
)

const MyOrders = (props) => {
    const [master] = useContext(MasterContext);
    const [user] = useContext(UserContext);

    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);

    const [timers, setTimers] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [closed, setClosed] = useState([]);
    const [opens, setOpens] = useState([]);


    const [thePopUp, setThePopUp] = useState("");

    const [intervalID, setIntervalID] = useState();

    const history = useHistory();

    // const [topSlides, setTopSlides] = useState();


    // useEffect(() => {
    //     mySocket.on("orderUpdated", (data) => {
    //         getAndSetAllAOrders();
    //     })

    // }, [mySocket])

    useEffect(() => {

        getAndSetAllAOrders();
    }, [])

    const getAndSetAllAOrders = async () => {

        if (user != null && user._id != null) {
            props.scrollToTop();
            props.shoTopStrp(false)
            props.showfotter(true)
            props.setShowbanners(false)

            let getIt = await utilsUsers.getUsersOrders(user._id)


            setClosedOpens(getIt)
            setAllOrders(getIt);
            getTimes(getIt)
        }
        else {
            history.push("./")
        }
    }

    const stopHandler = () => {
        var highestTimeoutId = setInterval(";");

        for (var i = 0; i < highestTimeoutId; i++) {
            clearInterval(i);
        }

    }

    const getTimes = (OrdrsList) => {

        stopHandler()
        startHandler(OrdrsList)
    }

    const startHandler = (OrdrsList) => {


        setIntervalID(setInterval(() => {

            OrdrsList.forEach((item, i) => {

                let theId = item.ord.orderNUM;
                var theELEMENT = window.document.getElementById(theId);
                if (theELEMENT) {
                    if (item.ord.orderStatus != "סגור" && item.ord.orderStatus != "מושהה" && item.ord.orderStatus != "מבוטל") {

                        let theItm = MyDateTime.getTimeLeftTilThen(item.ord.dateCreated, item.ord.OrderTimes);
                        if (!theItm.theEnd) {
                            theELEMENT.innerText = theItm.theTm
                        }
                        else if (theELEMENT.innerText == "") {
                            theELEMENT.innerText = "00:00";
                        }

                    }
                    else {
                        theELEMENT.innerText = "00:00";
                    }

                }
            });
        }, 1000));
    }


    let popTiming = -1;
    const duplicThisOrder = async (orderToDuplic, allOrdsLength) => {
        orderToDuplic.ord.orderNUM = "";
        orderToDuplic.ord._id = null;
        let restPage = await utilsRests.getRestById(orderToDuplic.buiss._id);

        let doDeliver = restPage.Delivery.Doing;
        let doTakeAway = restPage.TakeAway.Doing;

        //מחוץ לשעות הפעילות
        if (!MyDateTime.checkIfBetweenHours(restPage).openOrCLose) {

            popTiming++;

            setThePopUp(
                <Out_Of_Hours
                    okClick={() => { setThePopUp() }}
                    btn2Click={() => {
                        history.push(`/restaurants/${restPage.pageUrlFix != null &&
                            restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id}`)
                    }}
                />
            )


        }
        else {
            //לא מקבל משלוחים
            if (orderToDuplic.ord.OrderType == "Deliver" && !doDeliver) {
                setThePopUp(
                    <No_Deliverys
                        okClick={() => { setThePopUp() }}
                        btnClick2={() => history.push(`/restaurants/${restPage.pageUrlFix != null &&
                            restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id}`)}
                    />
                )
            }
            //לא מקבל Take
            else if (orderToDuplic.ord.OrderType == "Take" && !doTakeAway) {
                setThePopUp(
                    <No_TakeAway
                        okClick={() => setThePopUp()}
                        btnClick2={() => history.push(`/restaurants/${restPage.pageUrlFix != null &&
                            restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id}`)}
                    />
                )
            }

            //הכל טוב, משכפל את הזמנה
            else {
                setCurrentPage({ ...restPage, pageTYp: "withConPay" });

                setOrder(orderToDuplic.ord)

                history.push("/MyOrder");
            }

        }
    }

    const showThisOrder = (orderDivId) => {
        var ordDisp = window.document.getElementById(orderDivId);

        if (!ordDisp.classList.contains("showMe")) {
            ordDisp.classList.add("showMe");
            ordDisp.classList.remove("hideMe");

        } else {
            ordDisp.classList.add("hideMe");
            ordDisp.classList.remove("showMe");
        }
    }


    const orderConfirmd = (ord) => {
        setThePopUp(<BigPopUpMsg
            class={"oops"}
            key={popTiming}
            imgSrc={`${ConnUrls.servMediasUrl}${master.oopsPopImg}`}
            Title={["אישור קבלה"]}
            ContentText={["בלחיצה על אישור", "נדע שקיבלת את ההזמנה"]}
            inLabel={""}
            okClick={() => aprovedOrderRecive(ord)}
            BtnTxt={"אישור"}
            BtnTxt2={"חזור"}
            btnClick2={() => setThePopUp()}
            errorMsg={""} />
        )
    }


    const aprovedOrderRecive = async (ordr) => {

        await utilsOrders.updateStatus(ordr._id);
        setThePopUp();
        setClosedOpens(allOrders);

    }

    const goToBack = () => {
        history.goBack();

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }
        // else {
        //      history.push('/'); }
    }

    const setClosedOpens = (OrdrsList) => {

        let open = []
        let close = []

        OrdrsList.forEach((ord, indx) => {

            let inord = ord.ord;

            if (inord.orderStatus != "סגור" &&
                inord.orderStatus != "מושהה" &&
                inord.orderStatus != "מבוטל") {

                open.push(ord)
            }
            else {
                close.push(ord)
            }

        })

        setOpens(open);
        setClosed(close);


    }

    let countit = -1;

    return (
        <div className={"loginPage"}>
            <div className={"headOnFixC SecondFont bold big"}>
                ההזמנות שלי
                <div className={`backSd`}>
                    <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>
            </div>
            <br /><br /><br /><br />

            {/* {topSlides} */}
            {(opens.length > 0) ?
                <>
                    <div className={"MyOrdersLst"}>
                        <div className={"insideFrame"}>
                            {
                                opens.reverse().map((ord, indx) => {
                                    countit++;
                                    return (
                                        <div key={indx} className="onOrdItm">
                                            <div className={"MbigFont bold SecondFont onRight"}>{ord.buiss.buissName}</div>
                                            <div className={"smallbtnOrder onLeft pointing"} onClick={() => showThisOrder(`ordDetailsDiv_${ord.ord.orderNUM}`)}>הצג הזמנה</div>
                                            {/* <div className={"smallbtnOrder onLeft pointing"} onClick={() => orderConfirmd(ord.ord)}>אישור קבלה</div> */}
                                            <div className={"openOrdBx orderLINE"}>
                                                <div className={"small spaceTop"}>מספר הזמנה: {ord.ord.orderNUM}</div>
                                            </div>
                                            <div key={indx} className={"openOrdBx orderLINE"}>
                                                <div className={"countTxtOrder"}>
                                                    <div className={"big"}>{ord.ord.orderStatus}</div>
                                                </div>
                                                <div className={"countTimOrder huge onLeft"} id={`${ord.ord.orderNUM}`}></div>
                                                <div className={"veryVerySmall onLeft spaceTop"}> צפוי להגיע בעוד</div>
                                            </div>
                                            <div className={"ordDetailsDiv hideMe"} id={`ordDetailsDiv_${ord.ord.orderNUM}`}>
                                                <ShowMyOrder theOrder={ord.ord} />
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>

                    </div>
                </>
                : ""}
            {(closed.length > 0) ? <>
                <div className={"spaceRightLong MbigFont bold SecondFont"}>היסטוריית הזמנות</div>
                <div className={"MyOrdersLst historys"}>
                    <div className={"insideFrame"}>
                        {
                            closed.reverse().map((ord, indx) => {
                                countit++;
                                if (indx < 10) {
                                    return (
                                        <div key={indx} className={"openOrdBx orderLINE pointing"}>
                                            <div onClick={() => showThisOrder(`ordDetailsDivCls_${indx}`)}>
                                                <CheckCircleOutline sx={{ color: 'var(--priceText)' }} />
                                                {(MyDateTime.getDate(ord.dateCreated))} - {ord.buiss.buissName}</div>
                                            <div className={"ordDetailsDiv hideMe spaceTopBig"} id={`ordDetailsDivCls_${indx}`}>
                                                <div className={"smallbtnOrder onLeft"}
                                                    onClick={() => duplicThisOrder(allOrders[countit], allOrders.length)}>שכפל הזמנה</div>
                                                <div className={"countTxtOrder"}>
                                                    <div className={"big"}>סטטוס: {ord.ord.orderStatus}</div>
                                                </div>
                                                <div className={"openOrdBx orderLINE"}>
                                                    <div className={"small spaceTop"}>מספר הזמנה: {ord.ord.orderNUM}</div>
                                                </div>
                                                <ShowMyOrder theOrder={ord.ord} />
                                            </div>
                                        </div>
                                    )
                                }
                                else {
                                }
                            })
                        }
                    </div>

                </div>
            </>
                : (!closed.length > 0 && !opens.length > 0) ? <h3> <center> לא נמצאו הזמנות</center></h3> : ""}
            {thePopUp}
        </div >
    )

}

export default MyOrders