import { useContext, useEffect, useRef, useState ,createRef} from "react";
import { useRouteMatch } from "react-router-dom";

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { MenuItem, MenuList } from "@mui/material";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { CurrentPContext, ScreenWidthContext } from "../../ContextApi/context";
import ProductItem from "./ProductItem";
import ProductItemDeskTop from "./ProductItemDeskTop";
import utilsRestMenus from "../../_Utils/utilsRestMenus";
import Scroll from 'react-scroll';

var scroller = Scroll.scroller;


const TheRestMnu = (props) => {

    // const [currentPage] = useContext(CurrentPContext);
    // const [order, setOrder] = useContext(OrderContext);
    const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);
    const [buisMenu, setBuisMenu] = useState([]);
    const [value, setValue] = useState(0);
    // const [restProducts, setRestProducts] = useState([...props.Products]);
    const [sender, setSender] = useState(null);
    const [sectionRefs, setSectionRefs] = useState([]);

    const RestMnuRef = useRef(null);
    const [restMnuFxClass, setRestMnuFxClass] = useState("noFix");
    const [restMnuSpClass, setRestMnuSpClass] = useState("");

    const [mnuDivsPadd, setMnuDivsPadd] = useState("noPadd");

    const [searchTxt, setSearchTxt] = useState("")
    // const [searchMode, setSearchMode] = useState(false)

    const [onlyPhone, setOnlyPhone] = useState();
    // const [onlyPhone, setOnlyPhone] = useState(false);

  

    useEffect(() => {

        const getTheMenu = async () => {
            try {
                let mnubuis = await utilsRestMenus.getRestMenu();
                setBuisMenu(mnubuis);
                const newSectionRefs = mnubuis.map(() => createRef());
                setSectionRefs(newSectionRefs);


            } catch (error) {
                console.error("Error fetching menu:", error);
            }
        };

        getTheMenu();
        // if (!props.doDeliver && !props.doTakeAway) {
        //     setOnlyPhone(true)
        // }
        // else if (!MyDateTime.checkIfBetweenHours(currentPage).openOrCLose) {
        //     setOnlyPhone(true)
        // }

    }, [])
    

    useEffect(() => {
        const divElement = RestMnuRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);

      return () => {
        divElement.removeEventListener("scroll", handleScroll);
      };
    }
    }, []);


    const handleScroll = () => {
        // console.log("Scrolling div");
        // You can add more code related to scrolling here
      };



    // const handleScroll = () => {
    //     console.log(window.scrollY);
    //     const currentScrollY = window.scrollY;
    //     const fotterPos = window.document.getElementsByClassName("BottomMastScroll")[0].offsetTop;
    //     if (sectionRefs[value].current) {
    //       if (currentScrollY > sectionRefs[value].current.offsetTop && currentScrollY < fotterPos - 500) {
    //         setRestMnuFxClass("fixPos");
    //         setRestMnuSpClass("PaddMeFix");
    //         setMnuDivsPadd("withPadd");
    //       } else {
    //         setRestMnuFxClass("noFix");
    //         setRestMnuSpClass("");
    //         setMnuDivsPadd("noPadd");
    //       }
    //       spyTheBlock(currentScrollY, sectionRefs[value].current.offsetTop - 100);
    //     }
    // }

    // const spyTheBlock = (currentScrollY, startMnus) => {

    //     if (startMnus < currentScrollY) {
    //         for (let i = 0; i < sectionRefs.length; i++) {
    //             const element = sectionRefs[i];
    //             if (currentScrollY < element.offsetTop + element.offsetHeight - 150) {
    //                 setValue(i);
    //                 break;
    //             }
    //         }
    //     }

    // }

    const scrollToSection = (index) => {
        setValue(index)
        sectionRefs[index].current.scrollIntoView({ behavior: 'smooth' });
       
    };

    //   const handleChange = (newValue) => {
    //     setValue(newValue);
    //     scroller.scrollTo(`divBlock_${newValue}`, { smooth: true, offset: -140, duration: 1000 });

    // };


    // const handlebackToPos = (theID) => {

    //     scroller.scrollTo(`${theID}`, { smooth: true, offset: -100, duration: 1000 });

    // };

    // const serachMnuItm = (txtSearch) => {
    //     setSearchTxt(txtSearch)

    // }

    return (

        <div id="RestMnu" className="RestMnu" ref={RestMnuRef} >

            {/* טאבים ימין בדסקטופ */}
            {(screenWidth > 800) ?
                <div className={"rstMnuRight"}>
                    <div className={`tabsHeadPlace ${restMnuFxClass}`}>
                        {(searchTxt == "") ?
                            <div className={"fxPosWrap"}>
                                <Box sx={{ bgcolor: 'white', direction: 'rtl'}}>
                                    <MenuList
                                        orientation={"vertical"}
                                        value={value}
                                        // onChange={handleChange}

                                        aria-label="תפריט"
                                    >
                                        {
                                            buisMenu.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} onClick={() => scrollToSection(index)}
                                                        className={`tabClass ${(value == index) ? "selcedMnuIt" : ""}`}
                                                        style={{ color: "black" }} label={item.DivName}>{item.DivName}</MenuItem>)
                                            })
                                        }
                                    </MenuList>
                                </Box>
                            </div>
                            : ""}
                    </div>
                </div> : ""
            }

            <div className={`rstMnuWrap ${restMnuSpClass} ${(searchTxt == "") ? "" : "PaddMeFix"}`}>
                {/* <div className={"serachOnMnu"}>
                    {(searchTxt != "") ? <div onClick={() => serachMnuItm("")} ><HighlightOffIcon className="clearSrch" /> </div> : ""}
                    <input type={"text"} placeholder={`חפש בתפריט`} value={searchTxt} onChange={(e) => serachMnuItm(e.target.value)} />
                    <img id="searchBtn" src="/PermanentImages/searchIc.gif" alt="" />
                </div> */}

                {(screenWidth <= 800) ?
                    <div className={`tabsHeadPlace ${restMnuFxClass}`}>
                        {(searchTxt == "") ? <div className={"fxPosWrap"}>
                            <Box sx={{ bgcolor: 'white', direction: 'rtl' , height:"30px" }}>
                                {/* {(restMnuFxClass == "fixPos") ?
                                    <h2 className={"headOnFix bold SecondFontRegular big"}>
                                        {currentPage.buissName}
                                    </h2> : ""} */}
                                <Tabs
                                    value={value}
                                    // onChange={handleChange}
                                    variant="scrollable"
                                    aria-label="תפריט"
                                >
                                    {
                                        buisMenu.map((item, index) => {
                                            return (
                                                <Tab key={index}
                                                    onClick={() => scrollToSection(index)}
                                                    className={"tabClass"}
                                                    style={{ color: "black" }} label={item.DivName} />)
                                        })
                                    }
                                </Tabs>
                            </Box>
                        </div> : ""}
                    </div> : ""}

                {/* {(searchTxt == "") ?
                    <div className={`mnuDivs ${mnuDivsPadd}`}>
                        {

                            buisMenu.map((item, index) => {

                                let divsBk = item.Products.map((divitm, divIndex) => {
                                    if (!props.dispOnlyDelivery || divitm.okToDeliver) {
                                        if (screenWidth <= 800) {
                                            return (
                                                <ProductItem key={`${divIndex}_${index}`}
                                                    dops={0} itemId={`${divIndex}_${index}`}
                                                    divitm={divitm}
                                                    doDeliver={props.doDeliver}
                                                    doTakeAway={props.doTakeAway}
                                                    setSender={(senderId) => setSender(senderId)}
                                                    sender={sender}
                                                    dispOnlyDelivery={props.dispOnlyDelivery}
                                                    onlyPhone={onlyPhone}
                                                    myClass={(!divitm.inStock) ? "outOfStock" : ""}
                                                />
                                            )
                                        }
                                        else {
                                            return (
                                                <ProductItemDeskTop key={`${divIndex}_${index}`}
                                                    dops={0} itemId={`${divIndex}_${index}`}
                                                    doDeliver={props.doDeliver}
                                                    doTakeAway={props.doTakeAway}
                                                    divitm={divitm}
                                                    setSender={(senderId) => setSender(senderId)}
                                                    sender={sender}
                                                    dispOnlyDelivery={props.dispOnlyDelivery}
                                                    onlyPhone={onlyPhone}
                                                    myClass={(!divitm.inStock) ? "outOfStock" : ""}
                                                />
                                            )
                                        }
                                    }
                                });

                                let itmDesc = (item.DivDescript) ?
                                    <div className={`${(screenWidth <= 800) ? "regular divDescriptition" :
                                        "big"} bold SecondFontRegular`}>
                                        {item.DivDescript}</div> : "";

                                return (
                                    <div key={index} id={`divBlock_${index}`} className="divBlock">
                                        <h2 className={`divHead ${(screenWidth <= 800) ? "veryBig" : "anak"}
                                      bold SecondFontRegular`}> {item.DivName}</h2>
                                        {itmDesc}

                                        {divsBk}
                                    </div>
                                )
                            })}
                    </div>
                    : */}
                <div className={`mnuDivs ${mnuDivsPadd}`}>
                    {
                        buisMenu.map((item, index) => {
                            var cnt = 0;
                            let divsBk = item.Products.map((divitm, divIndex) => {

                                // if (divitm.prodName.includes(searchTxt)) {
                                cnt++;

                                // if (!props.dispOnlyDelivery || divitm.okToDeliver) {
                                if (divitm.inStock) {
                                    if (screenWidth <= 800) {
                                        return (
                                            <ProductItem key={`${divIndex}_${index}`}
                                                dops={0} itemId={`${divIndex}_${index}`}
                                                divitm={divitm}
                                                doDeliver={props.doDeliver}
                                                doTakeAway={props.doTakeAway}
                                                setSender={(senderId) => setSender(senderId)}
                                                sender={sender}
                                                dispOnlyDelivery={props.dispOnlyDelivery}
                                                onlyPhone={onlyPhone} />
                                        )
                                    }
                                    else {
                                        return (
                                            <ProductItemDeskTop key={`${divIndex}_${index}`}
                                                dops={0} itemId={`${divIndex}_${index}`}
                                                doDeliver={props.doDeliver}
                                                doTakeAway={props.doTakeAway}
                                                divitm={divitm}
                                                setSender={(senderId) => setSender(senderId)}
                                                sender={sender}
                                                dispOnlyDelivery={props.dispOnlyDelivery}
                                                onlyPhone={onlyPhone} myClass={"itmINSection"} />
                                        )
                                    }
                                }
                                //  }


                                // }
                            });
                            if (parseInt(cnt) > 0) {
                                return <div key={index} id={`divBlock_${index}`} ref={sectionRefs[index]} className="divBlock">
                                    <h2 className={"divHead veryBig bold SecondFontRegular"}> {cnt.length}{item.DivName}</h2>
                                    {divsBk}
                                </div>
                            }
                        })}
                </div>
                {/* } */}
            </div>

            {/* שמאל בדסקטופ */}
            {
                // (screenWidth > 800) ? <div className={"rstMnuLeft"}>
                //     <div className={"rstMnuLeftIn fxPosWrap"}>
                //         <div style={{ overflow: "hidden" }}>
                //             <div className={"bold"}>פרטי המסעדה:</div>
                //             <br />
                //             <a href={`tel:${props.restPage.buissPhone}`} className={"overFlHid"}>
                //                 <div className={"phoneLine"}>
                //                     <div className="clickPhoneBtn">{props.restPage.buissPhone}</div>
                //                 </div>
                //             </a>
                //             {(props.restPage.buissAddress) ? <AddressLine address={props.restPage.buissAddress} /> : ""}
                //             <DayOpeningHours item={props.restPage} />
                //             <BuisRating rating={props.restPage.buissRating} />
                //             {/* <div className={"opDot"}>
                //             <div className={`specDot ${!props.doDeliver ? "specDotClose" : ""}`}><FiberManualRecord /></div>
                //             <span className={"goRight bold"}>{!props.doDeliver ? "":"לא"} זמין למשלוחים</span>
                //         </div>
                //         <br />
                //         <div className={"opDot"}>
                //             <div className={`specDot ${!props.doTakeAway ? "specDotClose" : ""}`}><FiberManualRecord /></div>
                //             <span className={"goRight bold"}>{!props.doTakeAway? "":"לא"} זמין לאיסוף עצמי</span>
                //         </div> */}
                //         </div>
                //         <br />
                //         <Link to={`${url}/details`}>
                //             {/* <div className={"bold"} onClick={() => props.setShowHideMoreDet()}> */}
                //             <div className={"bold"} >
                //                 <span>עוד פרטים</span>
                //             </div>
                //         </Link>
                //         <br />
                //         <div className={"detailsLine moreDetBtn pointing"}>
                //             <div className={"bold"} onClick={() => props.setDispOnlyDelivery()}>
                //                 <span>{(props.dispOnlyDelivery) ? "הצג תפריט מלא" : "הצג תפריט משלוחים"} </span>
                //             </div>
                //         </div>
                //     </div>
                // </div> : ""
            }

        </div >
    );
}

export default TheRestMnu;
