import React, { useContext, useEffect, useState } from 'react'
import parse from 'html-react-parser';
import utilsContactPage from '../../../_Utils/utilsContactPage';
import myFunctions from '../../../myFunctions';
import TheRestMnu from '../../insideObjects/TheRestMnu';
import PopUpTheComp from '../../../DTechComp/PopUpTheComp';
import CustomDialogContent from '../../../DTechComp/CustomDialogContent';

const SectionRestMenu = (props) => {

    const [inputsParams, setInputsParams] = useState([])
    const [erMsg, setErMsg] = useState("");
    const [showSendBtn, setShowSendBtn] = useState(true)
    const [sending, setSending] = useState(false)
    const [thePopUp, setThePopUp] = useState("");

    useEffect(() => {

    }, [])

    const showMenu = () => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"menuPopBlock"}
                theInsideComp={
                    <TheRestMnu closePop={() => { setThePopUp() }} />}
                Title={[`תפריט`]}
                theTitlePopCss={"titleBig"}
                contentComponent={CustomDialogContent}
            // cancelClick={() => { setThePopUp() }}
            />
        )
    }

    return (
        <>

            <div className={"sectionBlock"} style={{

                left: props.item.blockLeft + "%",
                top: props.item.blockTop + "%",
                height: props.item.blockHeight + "%",
                width: props.item.blockWidth + "%",


            }}>

                <div className={"sectionBack"} style={{
                    backgroundColor: props.item.blockColor,
                    opacity: props.item.blockTransparent
                }}>
                </div>
               
                <div className={"IN_sectionBlock menuBtn"}>
                <button type="button" className="btnPopRegular menuBtn pointing"
                    onClick={() => showMenu()}>
                    הצג תפריט
                </button>
                </div>
            </div>
            {thePopUp}
        </>


    );
}

export default SectionRestMenu;